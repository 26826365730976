$COMPANY: "kpf";
            $NODE_ENV: "production";
            $secondary: #179346;;
            $slight   : #40bf62;;
            $sdark    : #00641b;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : #f92424;;
            
@import '~app/scss/global/GlobalStyles.module';

.ghost_block {
	height  : $GlobalStyles-standard_font;
	position: relative;
	display : inline-block;
	margin  : $GlobalStyles-standard_space/4;
}

.ghost {
	width   : 100%;
	position: relative;

	:global(.GlobalStyles-ghost_ui) {
		display: block;
	}
}