$COMPANY: "kpf";
            $NODE_ENV: "production";
            $secondary: #179346;;
            $slight   : #40bf62;;
            $sdark    : #00641b;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : #f92424;;
            
@import '~app/scss/global/GlobalStyles.module';

$AdFooterStyles-picture_size          : 150px;
$AdFooterStyles-picture_content-width : 320px;

.content {
	padding   : 0px;
	margin-top: $GlobalStyles-standard_space * 2;
	position  : relative;
	top       : 50px;
}

.wrapper {
	color      : $ColorStyles-ptext;
	padding-top: ($GlobalStyles-standard_space * 2);
	// background : darken($ColorStyles-plight,5%);
	// border     : 1px solid darken($ColorStyles-plight,10%);
}

.picture_container {
	text-align : center;
	position   : relative; 
	margin-top : -($GlobalStyles-standard_space * 3);
	z-index    : 2;
	padding    : 0px $GlobalStyles-standard_space/2;

	.picture_content {
		position    : relative;
		display     : inline-block;
	    z-index     : 2; 
	    white-space : normal;

		$AdFooterStyles-break-instance: $GlobalStyles-screen-desktop/$AdFooterStyles-picture_content-width;
		@for $i from 2 through $AdFooterStyles-break-instance {
			$AdFooterStyles-breakpoint:($i*$AdFooterStyles-picture_content-width);
			@if ($AdFooterStyles-breakpoint<$GlobalStyles-screen-desktop) {
				@media all and (min-width:($i*$AdFooterStyles-picture_content-width)) {
					width  : 100%/$i;
	   				padding:  0px $GlobalStyles-standard_space/2;
				}
			}
		}

		.picture_content_display {
			position  : relative;
	   		padding   : $GlobalStyles-standard_space;
		    // background: darken($ColorStyles-plight,1%);
			// border    : 1px solid darken($ColorStyles-plight,10%);
		}

	}
}

.company_info_wording {
	margin    : 0px;
	margin-top: -($GlobalStyles-standard_space*2); 
	padding   : $GlobalStyles-standard_space * 2;
	position  : relative; 

	&::before {
		@include ColorStyles-pdark-fading-edges;
		@include GlobalStyles-pseudo;
		height: 1px;
		width : 100%;
		top   : $GlobalStyles-standard_space * 4;
		left  : 0px;
	}
}

.footer_error {
	position: relative;
}

.wysiwyg {
	padding: $GlobalStyles-standard_space;
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	.content {
	    margin-top   : 0px;
	    margin-bottom: $GlobalStyles-standard_space;
	}
	.wrapper {
		padding-top: 0px;
	}


	.picture_container {
		margin-top: 0px;
		padding   : 0px;

		.picture_content {
		    margin-top   : 0px;
		    border       : 0px;
		}
	}

	.company_info_wording {
		margin-top: 0px; 
		opacity   : 1;
		padding   : $GlobalStyles-standard_space * 2  $GlobalStyles-standard_space;
	}
}