$COMPANY: "kpf";
            $NODE_ENV: "production";
            $secondary: #179346;;
            $slight   : #40bf62;;
            $sdark    : #00641b;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : #f92424;;
            
@import '~app/scss/global/GlobalStyles.module';
// Variables
$accordion-header-bg: #f0f0f0;
$accordion-content-bg: #ffffff;

// // Styles
.accordion {
  width: 100%;
}

.accordionItem {
  // border: 2px solid #005aa0 ;
  border: 2px solid $ColorStyles-secondary ;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;

  .accordionHeader {
    background-color: rgba(234, 234, 234, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;


    .accordionButton{
      font-size: 2em;
    }
  }

  .accordionContent {
    background-color: $accordion-content-bg;
    padding: 10px;
    transition: max-height 0.3s ease;
  }

  &.active {
    .accordionHeader {
      background-color: darken($accordion-header-bg, 5%);
    }
  }
}