$COMPANY: "kpf";
            $NODE_ENV: "production";
            $secondary: #179346;;
            $slight   : #40bf62;;
            $sdark    : #00641b;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : #f92424;;
            
@import '~app/scss/global/GlobalStyles.module';

.not_found {
	@include GlobalStyles-child-center('.not_found_image');
	position  : relative;
	display   : block;
	text-align: center;
	height    : 100%;
}

.not_found_image {
	position : relative;
	width    : 300px;
	max-width: 100%;
}

.has_expired {
	@include GlobalStyles-child-center('.has_expired_image');
	position  : relative;
	display   : block;
	text-align: center;
	height    : 100%;
}

.has_expired_image {
	position : relative;
	width    : 300px;
	max-width: 100%;
}