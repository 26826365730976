$COMPANY: "kpf";
            $NODE_ENV: "production";
            $secondary: #179346;;
            $slight   : #40bf62;;
            $sdark    : #00641b;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : #f92424;;
            
@import '~app/scss/global/GlobalStyles.module';

$GlobalStyles-input_box_size: ($GlobalStyles-input_sizing + 2px) - ($GlobalStyles-standard_space*3);

.container {
	position : relative;
	padding  : $GlobalStyles-standard_space*2  $GlobalStyles-standard_space*3;
    margin   : 0 auto;
    max-width: 1058px;

    ol, ul {
    	padding-left: 0px;
    }

    :global(.FormStyles-input_wrap) {
    	padding: $GlobalStyles-standard_space 0px;
    }
}

.stretched_image, .stretched_image img {
	width: 100%;
}

.delimiter {
	display: block;

	&::before {
		@include GlobalStyles-pseudo(relative);
		border-bottom: 1px solid $ColorStyles-primary;
	}
}

.block {
	position: relative;
    margin  : 0 auto;
}

.indention_1 {
	padding-left: $GlobalStyles-standard_space*2;
}

.indention_2 {
	padding-left: $GlobalStyles-standard_space*4;
}

.indention_3 {
	padding-left: $GlobalStyles-standard_space*6;
}

.indention_4 {
	padding-left: $GlobalStyles-standard_space*8;
}


.indention_5 {
	padding-left: $GlobalStyles-standard_space*10;
}

.align_center {
	text-align: center;
}

.align_justify {
	text-align: justify;
}

.align_right {
	text-align: right;
}

.stretched_0 {
	max-width: 550px;
}

.border_1 {
	border : 1px solid $ColorStyles-perror;
}

.fixed_1 {
	table {
		table-layout: fixed;
		width       : 100%;

		td {			
			overflow-wrap: break-word;
			word-wrap    : break-word;
		}
	}
}

.error {
	position: relative;
}

.image img {
	width: 100%;
}

.company_bank_account {
	border-left: 1px solid $ColorStyles-primary;
	padding    : $GlobalStyles-standard_space;

	.company_bank_name {
		font-weight: bold;
		padding    : $GlobalStyles-standard_space/4 0px;
	}

	.company_bank_currency,.company_bank_number {
		display: inline-block;
		padding: $GlobalStyles-standard_space/4;
	}

	.company_bank_currency {
		min-width: 50px;
	}
}

.custom_conflict_court,.custom_broker_branch {
	:global(.FormStyles-option_box) {
		display: block !important;
	}
}

.custom_broker_branch {

	> div, :global(.FormStyles-option_box_boundary) {
		width: 100%;
	}

	table {
		min-width: 100% !important;
	}

}

.custom_step_5_section_16 {
	
	li {
		padding: $GlobalStyles-standard_space/2;
		text-align: justify;
	}
	.custom_step_5_section_16_row {
		display       : flex;
		flex-direction: row;
		padding-top   : $GlobalStyles-standard_space/2;
		padding-bottom: $GlobalStyles-standard_space/2;
		text-align    : left;

		div:nth-child(1) {
			width: 20%;
		}
		div:nth-child(2) {
			width: 2%;
		}
		div:nth-child(3) {
			width: 78%;
			
			.banks {
				width        : 100%;
				margin-bottom: $GlobalStyles-standard_space;

				div {
					width: 100%;
				}			

				.bank_accounts {
					display       : flex;
					flex-direction: row;

					div:nth-child(1) {
						width : 10%;
					}
					div:nth-child(2) {
						width : 6%;
					}
					div:nth-child(3) {
						width : 90%;
					}
				}
			}
		}
	}
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	.container {

	    ol, ul {
	    	margin-top: $GlobalStyles-standard_space;
	    }

	    p {
	    	margin-top   : $GlobalStyles-standard_space*2;
	    	margin-bottom: $GlobalStyles-standard_space*2;
	    }
	}

	.header_large {
		font-size: $GlobalStyles-standard_font + 4px;
	}

	.header_medium {
		font-size  : $GlobalStyles-standard_font + 2;
	}

	.header_default {
		font-size: $GlobalStyles-standard_font;
	}

	.indention_1 {
		padding-left: $GlobalStyles-standard_space*1;
	}

	.indention_2 {
		padding-left: $GlobalStyles-standard_space*2;

		ol, ul, .checkbox {
	    	margin-left:$GlobalStyles-standard_space*2;
	    }
	}

	.indention_3 {
		padding-left: $GlobalStyles-standard_space*3;

		ol, ul, .checkbox {
	    	margin-left:$GlobalStyles-standard_space*3;
	    }
	}

	.indention_4 {
		padding-left: $GlobalStyles-standard_space*4;

		ol, ul, .checkbox {
	    	margin-left:$GlobalStyles-standard_space*4;
	    }
	}

	.custom_step_5_section_16 {

		.custom_step_5_section_16_row {		
			div:nth-child(1) {
				width: 25%;
			}
			div:nth-child(2) {
				width: 5%;
			}
			div:nth-child(3) {
				width: 70%;

				.banks {
					.bank_accounts {

						div:nth-child(1) {
							width : 17%;
						}
						div:nth-child(2) {
							width : 10%;
						}
					}
				}
			}
		}
	}

}

@media all and (max-width: $GlobalStyles-screen-tablet + (20.5 * 4px) -1px) {

	.fixed_1 {
		table {
			min-width: $GlobalStyles-screen-tablet;
		}
	}
}

@media all and (max-width: $GlobalStyles-screen-mobile - 1px) {
	.custom_step_5_section_16 {
		.custom_step_5_section_16_row {
			.banks {
				.bank_accounts {
					div:nth-child(1) {
						width: 32% !important;
					}
					div:nth-child(2) {
						width     : 20% !important;
						text-align: center;
					}
				}
			}
		}
	}
}