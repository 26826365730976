$COMPANY: "kpf";
            $NODE_ENV: "production";
            $secondary: #179346;;
            $slight   : #40bf62;;
            $sdark    : #00641b;;
            $stext    : #000000;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : #f92424;;
            
@import '~app/scss/global/GlobalStyles.module';
$CarouselStyles-arrow_width:24px;

.hqueue {
	width   : 100%;
	overflow: hidden;
	position: relative;
    height  : 100%;

	&.has_arrow.is_overflow {
		padding-left : $CarouselStyles-arrow_width;
		padding-right: $CarouselStyles-arrow_width;
	}

	&.scrolling {
		&::after {
			@include GlobalStyles-pseudo(absolute);
		}
	}

	.wrapper {
		@include GlobalStyles-hide-scrollbar;
		width         : 100%;
		overflow      : auto; 
		margin-bottom : -20px;
		padding-bottom: 20px;
		white-space   : nowrap;
		line-height   : 0px;

		> *  {
			white-space: normal;
			line-height: normal;
		}
	}

	.left_arrow,.right_arrow {
		@include GlobalStyles-child-center('.arrow_text');
		position  : absolute;
		height    : 100%;
		top       : 0px;
		width     : $CarouselStyles-arrow_width;
		text-align: center;
		color: $ColorStyles-secondary;

		&:hover {
			color: $ColorStyles-slight;
		}

		i {
			font-size : $GlobalStyles-standard_font + 8px; 
		}
	}

	.left_arrow {
		left: 0px;
	}

	.right_arrow {
		right: 0px;
	}
}